export default {
    name: "FormProducts",
    data() {
        return {
            productId: null,
            isLoading: true,
            process: false,
            form: {
                warehouse_id: null,
                ratio_2: null,
                ratio_3: null,
                unit_price: null,
                is_transit: true,
                stock: null,
                stock_type: null,
                product_name: "",
                product_image: "",
                product_id: null,
                product_sku: '',
                product_description: "",
                uom_1: '',
                uom_2: '',
                uom_3: '',
            },
            warehouseOptions: [],
            productOptions: [],
            stockOptions: [
               { value: 'good_stock', text: 'Stok Baik' },
               { value: 'bad_stock', text: 'Stok Buruk' },
               { value: 'destruction_warehouse', text: 'Pemusnahan Stok' },
           ],
        }
    },

    props: {
        formData: {
            default: null,
            type: Object,
            required: true
        },
        formMode: {
            default: '',
            type: String,
            required: true
        },
    },

    computed: {
        disabledSave() {
            let notValid = false;

            if(this.form.stock === '' || this.form.stock === null) {
                notValid = true;
            }
            if(this.form.warehouse_id === '' || this.form.warehouse_id === null) {
                notValid = true;
            }
            if(this.form.product_id === '' || this.form.product_id === null) {
                notValid = true;
            }
            if(this.form.stock_type === '' || this.form.stock_type === null) {
                notValid = true;
            }

            return notValid
        }
    },

    methods: {
        initForm() {
            this.isLoading = true;
            if(this.formMode === 'edit') {
                this.productId = this.formData.id;
                this.dialogImageUrl = this.formData.image_url? this.formData.image_url: '';
                this.form.name = this.formData.name;
                this.form.image_url= this.formData.image_url? this.formData.image_url:'';

                this.isLoading = false;
            }else{
                    this.form = {
                        warehouse_ids: null,
                        main_supplier: "",
                        ratio_2: null,
                        ratio_3: null,
                        unit_price: null,
                        is_transit: false,
                        stock: null,
                        product_name: "",
                        product_image: "",
                        product_id: null,
                        product_sku: '',
                        product_description: "",
                        uom_1: '',
                        uom_2: '',
                        uom_3: '',
                    }
                this.isLoading = false;
            }

        },

        validate(evt) {
            var theEvent = evt || window.event;
          
            // Handle paste
            if (theEvent.type === 'paste') {
                key = event.clipboardData.getData('text/plain');
            } else {
            // Handle key press
                var key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
            }
            var regex = /[0-9]|\./;
            if( !regex.test(key) ) {
              theEvent.returnValue = false;
              if(theEvent.preventDefault) theEvent.preventDefault();
            }
          },

        async saveData() {
            let self = this;
            self.process = true;

            const payload = this.form;
            
            if (payload.stock) payload.stock = Number(payload.stock);

            if (payload.product_id) {
                if (payload.ratio_2) payload.ratio_2 = Number(payload.ratio_2);
                if (payload.ratio_3) payload.ratio_3 = Number(payload.ratio_3);
                if (payload.unit_price) payload.unit_price = Number(payload.unit_price);
            }

            setTimeout(() => {
                if(this.formMode === 'add') {
                    self.$store.dispatch('addWarehouseStock', payload)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Membuat Gudang Stok Berhasil!',
                            'success'
                            )
                    })
                    .catch(err => {
                        self.process = false;
                        swal.fire(
                            'Failed!',
                            'Membuat Gudang Stok Gagal '+ err.message,
                            'error'
                        )
                    })
                }else{
                    let dataNeed = {
                        id : this.productId,
                        form : payload
                    }
                    self.$store.dispatch('UpdateProduct', dataNeed)
                    .then(res => {
                        this.$emit("closeModal", true);
                        setTimeout(() => {
                            swal.fire(
                                'Success!',
                                'Update Product Success!',
                                'success'
                                )
                        }, 300);
                    })
                    .catch(err => {
                        self.process = false;
                        swal.fire(
                            'Failed!',
                            'Update Product Failed. '+ err.message,
                            'error'
                        )
                    })
                }
            }, 500);
            
        },

        handleSelectProduct(productId) {
            const productData = this.productOptions.find(val => val.id === productId);
            if (productData) {
                this.form.product_id = productData.id;
                this.form.product_image = productData.image_url;
                this.form.product_name = productData.name;
                this.form.product_description = productData.description;
                this.form.product_sku = productData.product_sku;
                this.form.ratio_2 = productData.ratio_2;
                this.form.ratio_3 = productData.ratio_3;
                this.form.unit_price = productData.unit_price;
                this.form.uom_1 = productData.uom_1;
                this.form.uom_2 = productData.uom_2;
                this.form.uom_3 = productData.uom_3;
            }
        },

        getMasterData() {
            this.warehouseOptions = [];

            let searchQ = {
                limit: 10000,
                page: 1
            };

            this.$store.dispatch('getWarehouse', searchQ)
            .then(res => {
                const { data: { data } } = res;
                this.warehouseOptions = data || [];
            })

            this.$store.dispatch('getProduct', searchQ)
            .then(res => {
                const { data: { data } } = res;
                this.productOptions = data || [];
            })

        },

    },
    mounted() {
        this.getMasterData();
        this.initForm();
    }
}