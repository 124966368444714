export default {
    name: "FormProducts",
    data() {
        return {
            productId: null,
            formData : null,
            formMode: 'add',
            isLoading: true,
            form: {
                category_id: null,
                channel_id: null,
                name: "",
                image_url: "",
                warehouse_ids: [],
                main_supplier: "",
                ratio_2: null,
                ratio_3: null,
                unit_price: null,
                initial_stock: null,
                description: "",
                sku: '',
                uom_1: '',
                uom_2: '',
                uom_3: '',
            },
            dialogImageUrl:'',
            imgTemp: null,
            catOptions: [],
            channelOptions: [],
            warehouseOptions: [],
            typesOptions: [
                // {
                //     label: "Item",
                //     value: "sameday",
                // },
                {
                    label: "Kilogram",
                    value: "kilogram",
                },
                {
                    label: "Viss",
                    value: "viss",
                }
            ],
        }
    },

    computed: {
        disabledSave() {
            let notValid = false;

            if(this.form.name === '' || this.form.name === null) {
                notValid = true;
            }
            if(this.form.category_id === '' || this.form.category_id === null) {
                notValid = true;
            }
            if(this.form.channel_id === '' || this.form.channel_id === null) {
                notValid = true;
            }
            if(this.formMode === 'add' && (this.form.warehouse_ids.length === 0 || this.form.warehouse_ids === null)) {
                notValid = true;
            }
            if(this.form.unit_price === '' || this.form.unit_price === null) {
                notValid = true;
            }
            if(this.form.sku === '' || this.form.sku === null) {
                notValid = true;
            }

            return notValid
        }
    },

    methods: {
        initForm() {
            this.isLoading = true;
            if(this.formMode === 'edit') {
                this.productId = this.formData.id;
                this.dialogImageUrl = this.formData.image_url? this.formData.image_url: '';
                this.form.category_id = Number(this.formData.category_id);
                this.form.channel_id = Number(this.formData.channel_id);
                this.form.name = this.formData.name;
                this.form.description = this.formData.description;
                this.form.image_url= this.formData.image_url? this.formData.image_url:'';
                this.form.main_supplier= this.formData.main_supplier? this.formData.main_supplier:'';
                this.form.ratio_2= this.formData.ratio_2? this.formData.ratio_2:'';
                this.form.ratio_3= this.formData.ratio_3? this.formData.ratio_3:'';
                this.form.sku= this.formData.sku? this.formData.sku:'';
                this.form.uom_1= this.formData.uom_1? this.formData.uom_1:'';
                this.form.uom_2= this.formData.uom_2? this.formData.uom_2:'';
                this.form.uom_3= this.formData.uom_3? this.formData.uom_3:'';
                this.form.unit_price= this.formData.unit_price? this.formData.unit_price:'';
                this.form.unit_price= this.formData.unit_price? this.formData.unit_price:'';
                // this.form.initial_stock= this.formData.initial_stock? this.formData.initial_stock:'';
                // this.form.warehouse_ids = this.formData.warehouse_ids? this.formData.warehouse_ids: [];

                this.isLoading = false;
            }else{
                    this.form = {
                        category_id: null,
                        channel_id: null,
                        name: "",
                        image_url: "",
                        warehouse_ids: [],
                        main_supplier: "",
                        ratio_2: null,
                        ratio_3: null,
                        unit_price: null,
                        initial_stock: null,
                        description: "",
                        sku: '',
                        uom_1: '',
                        uom_2: '',
                        uom_3: '',
                    }
                this.isLoading = false;
            }

        },

        selectFile(i = null)
        {
                $('#inputIMG'+i).click();
        },
        previewImage: function(event, i) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    if(i !== null) {
                        this.imgVariant[i].dialogImageUrlVariant = e.target.result;
                    }else {
                        this.dialogImageUrl = e.target.result;
                    }
                }
                reader.readAsDataURL(input.files[0]);
            }
        },

        validate(evt) {
            var theEvent = evt || window.event;
          
            // Handle paste
            if (theEvent.type === 'paste') {
                key = event.clipboardData.getData('text/plain');
            } else {
            // Handle key press
                var key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
            }
            var regex = /[0-9]|\./;
            if( !regex.test(key) ) {
              theEvent.returnValue = false;
              if(theEvent.preventDefault) theEvent.preventDefault();
            }
          },

        async saveData() {
            let self = this;

            if(self.imgTemp) {
                let fd = new FormData();
                fd.append('file', self.imgTemp);
                fd.append('filename', self.imgTemp.name);

                await self.$store.dispatch('uploadFile', fd)
                .then(async res => {
                    this.form.image_url = await res.data.url
                }).catch(err => {
                   return this.$bvToast.toast(
                        err.message,
                        {
                        title: "Upload image Failed",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: false,
                        toaster: "b-toaster-top-right"
                        }
                    );
                })
            }

            const payload = this.form;
            
            if (payload.ratio_2) payload.ratio_2 = Number(payload.ratio_2);
            if (payload.ratio_3) payload.ratio_3 = Number(payload.ratio_3);
            if (payload.unit_price) payload.unit_price = Number(payload.unit_price);
            if (payload.initial_stock) payload.initial_stock = Number(payload.initial_stock);
            if (payload.category_id) payload.category_id = payload.category_id.toString();
            if (payload.channel_id) payload.channel_id = payload.channel_id.toString();
            
            if(this.formMode === 'add') {
                await self.$store.dispatch('addProduct', payload)
                .then(res => {
                    swal.fire(
                        'Success!',
                        'Create Product Success!',
                        'success'
                        )
                    this.$router.push('/product')
                })
                .catch(err => {
                    swal.fire(
                        'Failed!',
                        'Create Product Failed. '+ err.message,
                        'error'
                    )
                })
            }else{

             delete payload.warehouse_ids;
             delete payload.initial_stock;

                let dataNeed = {
                    id : this.productId,
                    form : payload
                }
                await self.$store.dispatch('UpdateProduct', dataNeed)
                .then(res => {
                    setTimeout(() => {
                        swal.fire(
                            'Success!',
                            'Update Product Success!',
                            'success'
                            )
                        this.$router.push('/product')
                    }, 300);
                })
                .catch(err => {
                    swal.fire(
                        'Failed!',
                        'Update Product Failed. '+ err.message,
                        'error'
                    )
                })
            }
            
        },

        getMasterData() {
            this.catOptions = [];
            this.warehouseOptions = [];
            this.channelOptions = [];

            let searchQ = {
                page: 1,
                pageSize: 1000
            };

            this.$store.dispatch('getWarehouse', searchQ)
            .then(res => {
                const { data: { data } } = res;
                this.warehouseOptions = data || [];
            })

            this.$store.dispatch('getProductCat', searchQ)
            .then(res => {
                const { data: { data } } = res;
                this.catOptions = data || [];
            })

            this.$store.dispatch('getChannel', searchQ)
            .then(res => {
                const { data: { data } } = res;
                this.channelOptions = data || [];
            })

        },

        getDetailProduct(id) {
            this.$store.dispatch('getProductDetail',id)
            .then(res => {
                this.formData = res.data;
                document.title = 'Produk ' + this.formData.name + '- Kujangmas Admin';
                this.initForm();
            })
        },

    },
    mounted() {
        this.getMasterData();
        if(this.$route.params && this.$route.params.id) {
            this.formMode = 'edit';
            this.getDetailProduct(this.$route.params.id)
        }else{
            document.title = 'Tambah Produk - Kujangmas Admin';
            this.initForm();
        }

    }
}