<template>
    <div class="row my-5">
        <div class="col-12 mb-5">
           <h2>Terms and Condition Japfa Chicken Eggs App</h2>
           <h6 class="text-muted">Market place for egg product, chicken and others</h6>
        </div>
        <div class="col-12 text-justify">
            <h5>1. Terms Of Use </h5>
            <p>These Terms of Use (“Terms”) govern your use of the websites and mobile applications provided by Chicken Eggs (or referred to as “us”) (collectively the “Platforms”). Please read these Terms carefully. By accessing and using the Platforms, you agree that you have read, understood and accepted the Terms including any additional terms and conditions and any policies referenced herein, available on the Platforms or available by hyperlink. If you do not agree or fall within the Terms, please do not use the Platforms.

                The Platforms may be used by (i) natural persons who have reached 18 years of age and (ii) corporate legal entities, e.g companies. Where applicable, these Terms shall be subject to country-specific provisions as set out herein. 

                Users below the age of 18 must obtain consent from parent(s) or legal guardian(s), who by accepting these Terms shall agree to take responsibility for your actions and any charges associated with your use of the Platforms and/or purchase of Goods. If you do not have consent from your parent(s) or legal guardian(s), you must stop using/accessing the Platforms immediately. 

                Chicken Eggs reserves the right to change or modify these Terms (including our policies which are incorporated into these Terms) at any time. You are strongly recommended to read these Terms regularly. You will be deemed to have agreed to the amended Terms by your continued use of the Platforms following the date on which the amended Terms are posted.</p>
            
            <h5>2. Use of the Platforms and Chicken Eggs Account </h5>
            <p>2.1 You will need to register for a Chicken Eggs account for you to use the Platform. When you register for a Chicken Eggs account we will ask you to provide your personal information including a valid email address, a mobile phone number and a unique password. To purchase an Order, depending on which payment method you opt for, you may need to provide us with your credit card details. Your unique password should not be shared with anyone and you agree to keep it secret at all times. You are solely responsible for keeping your password safe. Save for cases of fraud or abuse which are not your fault, you accept that all Orders placed under your Chicken Eggs account are your sole responsibility. 

                2.2 Chicken Eggs shall not be liable for Orders that encounter delivery issues due to incomplete, incorrect or missing information provided by you. You are obliged to provide information that is complete, accurate and truthful for the proper processing of the Order, including your delivery address and contact information.

                2.3 If you wish to delete your Chicken Eggs account, please send us an email requesting the same. We may restrict, suspend or terminate your Chicken Eggs account and/or use of the Platforms, if we reasonably believe that: 

                2.3.1 someone other than you is using your Chicken Eggs account; or 

                2.3.2 where you are suspected or discovered to have been involved in any activity or conduct that is in breach of these Terms, our policies and guidelines, or involved in activity or conduct which we deem in our sole discretion to be an abuse of the Platforms.</p>

            <h5>3. Restrictions </h5>

            <p>
                3.1 Activities Prohibited on the Platforms

                The following is a non-exhaustive list of the types of conduct that are illegal or prohibited on the Platforms. Chicken Eggs reserves the right to investigate and take appropriate legal action against anyone who, in Chicken Eggs's sole discretion, engages in any of the prohibited activities. Prohibited activities include, but are not limited to the following:

                3.1.1 using the Platforms for any purpose in violation of local, state, or federal laws or regulations;

                3.1.2 posting any content that infringes the intellectual property rights, privacy rights, publicity rights, trade secret rights, or any other rights of any party;

                3.1.3 posting content that is unlawful, obscene, defamatory, threatening, harassing, abusive, slanderous, hateful, or embarrassing to any other person or entity as determined by Chicken Eggs in its sole discretion or pursuant to local community standards;

                3.1.4 posting content that constitutes cyber-bullying, as determined by Chicken Eggs in its sole discretion;

                3.1.5 posting content that depicts any dangerous, life-threatening, or otherwise risky behavior;

                3.1.6 posting telephone numbers, street addresses, or last names of any person;

                3.1.7 posting URLs to external websites or any form of HTML or programming code;

                3.1.8 posting anything that may be “spam,” as determined by Chicken Eggs in its sole discretion;

                3.1.9 impersonating another person when posting content;

                3.1.10 harvesting or otherwise collecting information about others, including e-mail addresses, without their consent;

                3.1.11 allowing any other person or entity to use your identification for posting or viewing comments;

                3.1.12harassing, threatening, stalking, or abusing any person on the Platforms;

                3.1.13engaging in any other conduct that restricts or inhibits any other person from using or enjoying the Websites, or which, in the sole discretion of Chicken Eggs, exposes Chicken Eggs or any of its customers, suppliers, or any other parties to any liability or detriment of any type; or

                3.1.14encouraging other people to engage in any prohibited activities as described herein. 

                3.2 Chicken Eggs reserves the right but is not obligated to do any or all of the following:

                3.2.1 investigate an allegation that any content posted on the Platforms does not conform to these Terms and determine in its sole discretion to remove or request the removal of the content;

                3.2.2 remove content which is abusive, illegal, or disruptive, or that otherwise fails to conform with these Terms;

                3.2.3 suspend or terminate a user’s access to the Platforms or their Chicken Eggs Account upon any breach of these Terms;

                3.2.4 monitor, edit, or disclose any content on the Platforms; and

                3.2.5 edit or delete any content posted on the Platforms, regardless of whether such content violates these standards.

            </p>

            <h5>4. Intellectual Property  </h5>

            <p>
                All trademarks, logos, images, and service marks, including these Terms as displayed on the Platforms or in our marketing material, whether registered or unregistered, are the intellectual property of Chicken Eggs and/or third parties who have authorised us with the use (collectively the “Trademarks”). You may not use, copy, reproduce, republish, upload, post, transmit, distribute, or modify these Trademarks in any way without our prior express written consent. The use of Chicken Eggs's trademarks on any other website not approved by us is strictly prohibited. Chicken Eggs will aggressively enforce its intellectual property rights to the fullest extent of the law, including criminal prosecution. Chicken Eggs neither warrants nor represents that your use of materials displayed on the Platforms will not infringe rights of third parties not owned by or affiliated with Chicken Eggs. Use of any materials on the Platforms is at your own risk.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "TermsCondition",
    mounted() {
        document.title = 'Japfa - Terms Condition'
    }
}
</script>