export default {
    name: "FormUser",
    data() {
        return {
            process: false,
            userId: null,
            form: {
                address: "",
                name: "",
                code: "",
                description: "",
            }
        }
    },

    props: {
        formData: {
            default: null,
            type: Object,
            required: true
        },
        formMode: {
            default: '',
            type: String,
            required: true
        },
    },

    computed: {
        disabledSave() {
            let invalid = false;

            if(this.form.description === '' || this.form.description === null) {
                invalid = true;
            }
            if(this.form.name === '' || this.form.name === null) {
                invalid = true;
            }
            if(this.form.code === '' || this.form.code === null) {
                invalid = true;
            }
            if(this.form.address === '' || this.form.address === null) {
                invalid = true;
            }

            return invalid
        }
    },

    methods: {
        initForm() {
            if(this.formMode === 'edit') {
                this.userId = this.formData.id;
                this.form.address= this.formData.address;
                this.form.name= this.formData.name;
                this.form.code= this.formData.code;
                this.form.description= this.formData.description;
            }else{
                    this.form = {
                        address: "",
                        name: "",
                        code: "",
                        description: "",
                    }
            }
        },

        async saveData() {
            let self = this;
            self.process = true;

            setTimeout(() => {
                if(this.formMode === 'add') {
                    self.$store.dispatch('addWarehouse', this.form)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Tambah Gudang Berhasil!',
                            'success'
                            )
                        self.process = false;
                    })
                    .catch(err => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Failed!',
                            'Tambah Gudang Gagal. '+ err.message,
                            'error'
                        )
                        self.process = false;
                    })
                }else{
                    let dataNeed = {
                        id : this.userId,
                        form : this.form
                    }

                    self.$store.dispatch('UpdateWarehouse', dataNeed)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Update Gudang Berhasil!',
                            'success'
                            )
                            self.process = false;
                    })
                    .catch(err => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Failed!',
                            'Update Gudang Gagal. '+ err.message,
                            'error'
                        )
                        self.process = false;
                    })
                }
            }, 500);
            
        },

    },
    mounted() {
        this.initForm();
    }
}