import FormWarehouseStock from './Form-WarehouseStock/Form-WarehouseStock.vue';

export default {
    name: 'Warehouse',
    components : { FormWarehouseStock },
    data() {
        return {
            form:{
                name: 'Veronica',
                company: 'Veridian Dynamics'
            },
            search : {
                keyword : '',
                warehouse_id: null,
                stock_type: null,
            },
            editForm: {
                stock: 0,
            },
            warehouseOptions: [],
            modeForm: 'add',
            loading: true,
            isMode : 'show',
            total_items: 0,
            currentPage: 1,
            isContent : 'Barang',
            listHead : [
                {
                    title: 'Barang',
                    notif : null
                }
            ],
             stockOptions: [
                { value: null, text: 'Pilih Stok', disabled: true },
                { value: 'good_stock', text: 'Stok Baik' },
                { value: 'bad_stock', text: 'Stok Buruk' },
                { value: 'destruction_warehouse', text: 'Pemusnahan Stok' },
            ],
            dataList : {
                data : [],
                detailData: {},
                _loading : true,
                pagination: {
                    "page": 1,
                    "total_pages": 1,
                    "total_records": 1,
                    "prev": 1,
                    "next": 1,
                    "record_per_page": 1
                },
                limit: 5,
            },
        }
    },

    watch: {
        "currentPage"()
        {
            let self = this

            if(self.currentPage != 1)
            {
                    self.loadData()
            }else {
                    self.loadData()
            }
        },
    },
    computed : {
        totalItem(){

            return this.total_items
        },
    },

    methods: {
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        closeModal(e) {
            this.$bvModal.hide('modal-warehouse-form')
            this.loadData()
        },
        initForm()
        {
            this.dataList.form = [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
        },
        addForm()
        {
            this.modeForm = 'add';
            this.$bvModal.show('modal-warehouse-form')
            
        },
        removeForm(x)
        {
            if(this.dataList.form.length == 1)
            {
                return null
            }else {
                return  this.dataList.form.splice(x, 1);
            }
            
        },
        editData(data)
        {
            this.modeForm = 'edit';
            // this.$store.dispatch('getUsersDetail',id)
            // .then(res => {
            //     res.date_formatted = Moment(res.date_formatted).format('DD MMM YYYY')
            //     this.dataList.detailData = res
            //     this.$bvModal.show('modal-product-form')
            // })

            this.dataList.detailData = data;
            this.$bvModal.show('modal-warehouse-form')
        },
        restockForm(data)
        {
            this.dataList.detailData = data;
            this.editForm.stock = null;
            this.$bvModal.show('modal-restock')
        },
        moveStock(data, type) {
            swal.fire({
                title: 'Apakah Kamu yakin?',
                text: 'Stok '+ data.product_name +` akan diubah menjadi ${this.mappingStockType(type)}! Silahkan isi kuantitas. `,
                icon: 'warning',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Batalkan',
                showLoaderOnConfirm: true,
                preConfirm: (qty) => {
                    if(qty === '')
                    {
                        return swal.showValidationMessage(
                            `Required!`
                        )
                    } else if(Number(qty) > data.stock)
                    {
                        return swal.showValidationMessage(
                            `Tidak dapat melebihi kuantitas stok`
                        )
                    }
                },
                }).then(async result => {
                if (result.value) {

                    const dataNeed = {
                        "id": data.id,
                        form: {
                            "id": data.id,
                            "stock":  Number(result.value) || null,
                            "stock_type": type
                        }
                    }
                    await this.$store.dispatch('UpdateWarehouseStockType',dataNeed).then(res => {
                            swal.fire(
                            'Success',
                            `Mengubah stok ${data.product_name} menjadi ${this.mappingStockType(type)} Berhasil!`,
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            `Mengubah stok ${data.product_name} menjadi ${this.mappingStockType(type)} Gagal!`,
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        async submitRestock() {

            const dataNeed = {
                id: this.dataList.detailData.id,
                form: {
                    id: this.dataList.detailData.id,
                    new_stock: Number(this.editForm.stock),
                }
            }
            await this.$store.dispatch('UpdateWarehouseStock',dataNeed).then(res => {
                    swal.fire(
                    'Success',
                    `Restock ${this.dataList.detailData.product_name} Berhasil!`,
                    'success'
                )
                this.$bvModal.hide('modal-restock')
                this.loadData()
            }).catch(err => {
                swal.fire(
                    'Error',
                    'Restock Gagal!',
                    'error'
                )
            })
        },
        deleteData(id, nama)
        {
            swal.fire({
                title: 'Apakah Kamu yakin?',
                text: 'Gudang '+ nama +' akan dihapus secara permanen!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batalkan'
                }).then(async result => {
                if (result.value) {

                    await this.$store.dispatch('DeleteWarehouseStock',id).then(res => {
                            swal.fire(
                            'Success',
                            'Hapus Gudang '+ nama +' Berhasil!',
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            'Hapus Gudang Gagal!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        mappingStockType(type) {
            switch (type) {
                case 'good_stock':
                    return 'Stok Baik'
                case 'bad_stock':
                    return 'Stok Buruk'
                case 'destruction_warehouse':
                    return 'Pemusnahan Stok '
            
                default:
                    '-'
                    break;
            }
        },
        loadData(page)
        {
            let self = this;
            page = page || 1

            self.dataList._loading = true
            self.dataList.data = []
            let searchQ = {
                is_transit: false,
                warehouse_id: self.search.warehouse_id,
                stock_type: self.search.stock_type,
                keyword: self.search.keyword,
                pageSize: Number(this.dataList.limit),
                page: page,
            };

            self.$store.dispatch('getWarehouseStock', searchQ)
            .then(res => {
                const { data } = res;
                if(res.data && data.data.length > 0)
                {
                    data.data.forEach(el => {
                        el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                        self.dataList.data.push(el)
                    })
                }
            self.dataList.pagination = data.metadata
            self.total_items = data.metadata.totalData
            self.dataList._loading = false;
            })
            .catch(() => {
            self.total_items = 0
            self.dataList._loading = false;
            });
        },

        getOverallIndex(index) {
            return (this.dataList.pagination.page* this.dataList.limit)-this.dataList.limit + index + 1
          },

          getMasterData() {
            this.warehouseOptions = [];

            let searchQ = {
                page: 1,
                pageSize: 1000
            };

            this.$store.dispatch('getWarehouse', searchQ)
            .then(res => {
                const { data: { data } } = res;
                this.warehouseOptions = data || [];
                this.warehouseOptions.unshift({
                    id: null,
                    name: 'Pilih Gudang',
                    code: '',
                })
            })
        },
    },
    mounted()
    {
      document.title = 'Gudang Stok - Kujangmas Admin'
        this.loadData();
        this.getMasterData();
    }
}