import DetailTransaction from './Detail-Transaction/Detail-Transaction.vue';
import axiosAuth from "../../../plugins/axiosAuth";

import { jsPDF } from "jspdf";
import 'jspdf-autotable';

export default {
    name: 'Transaction',
    components : { DetailTransaction },
    data() {
        return {
            centerDialogVisible: false,
            noPhoto: require('@/assets/img/icon/no-photo.jpg'),
            imagePDF: require('@/assets/img/japfa-logo-text.png'),
            form:{
                name: 'Veronica',
                company: 'Veridian Dynamics'
            },
            search : {
                keyword : '',
                status: 'pending',
                store_id: null,
                sales_id: null
            },
            courierList: [],
            selectAll: false,
            filterDate: '',
            modeForm: 'add',
            loading: true,
            isMode : 'show',
            total_items: 0,
            currentPage: 1,
            isContent : 'Barang',
            listHead : [
                {
                    title: 'Barang',
                    notif : null
                }
            ],
            storeOptions: [],
            salesOptions: [],
            options: [
                { value: '', text: 'Pilih Unit', disabled: true },
                { value: 'bal', text: 'bal' },
                { value: 'dus', text: 'dus' },
                { value: 'pak', text: 'pak' },
                { value: 'krg', text: 'krg' },
                { value: 'kg', text: 'kg' },
                { value: 'pcs', text: 'pcs' },
                { value: 'rcg', text: 'rcg' },
                { value: 'peti', text: 'peti' },
            ],
            dataList : {
                data : [],
                detailData: {},
                _loading : false,
                form : [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
            },

        pickerOptions: {
            shortcuts: [{
                text: 'Last week',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
                }
            }, {
                text: 'Last month',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
                }
            }, {
                text: 'Last 3 months',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
                }
            }]
        },
        courierSelected: null,
        courierSelectedName: null,
        }
    },

    watch: {
        // "search.keyword"()
        // {
        //     if(this.search.keyword !== '')
        //     {
        //             this.loadData()
        //     }else
        //     {
        //             this.loadData()
        //     }
        // },
        "currentPage"()
        {
            let self = this

            if(self.currentPage != 1)
            {
                    self.loadData()
            }else {
                    self.loadData()
            }
        },
    },
    computed : {
        totalItem(){

            return this.total_items
        },
        disabledUpdate() {
            let disabled = true;

            if(this.dataList.data.length > 0) {
                this.dataList.data.forEach(el => {
                    if(el.isSelect === true) {
                        disabled = false;
                    }
                })
            }

            return disabled;
        }
    },

    methods: {
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        closeModal(e) {
            this.$bvModal.hide('modal-transaction-form')
            this.loadData()
        },
        initForm()
        {
            this.dataList.form = [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
        },
        addForm()
        {
            this.modeForm = 'add';
            this.$bvModal.show('modal-transaction-form')
            
        },
        removeForm(x)
        {
            if(this.dataList.form.length == 1)
            {
                return null
            }else {
                return  this.dataList.form.splice(x, 1);
            }
            
        },
        editData(data)
        {
            // this.modeForm = 'edit';
            this.dataList.detailData = null;
            this.$store.dispatch('getTransactionDetail',data.id)
            .then(res => {
                const { data } = res;

                data.date_formatted = Moment(data.created_at).format('DD MMM YYYY')
                this.dataList.detailData = data;
                setTimeout(() => {
                    this.$bvModal.show('modal-transaction-form')
                }, 150);
            })
        },
        deleteData(id, nama)
        {
            let self = this;
            swal.fire({
                title: 'Are You Sure?',
                text: 'Product '+ nama +' will delete permanently!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel'
                }).then(async result => {
                if (result.value) {
                    await this.$store.dispatch('DeleteProduct',id).then(res => {
                            swal.fire(
                            'Success',
                            'Delete Product '+ nama +' Success!',
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            'Delete Product Failed!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        loadData()
        {
            let self = this;
            self.dataList._loading = true
            self.dataList.data = []
            let searchQ = {
                keyword: self.search.keyword,
                status: self.search.status,
                start_date : self.filterDate !== '' && self.filterDate !== null ? Moment(this.filterDate[0]).format('YYYY-MM-DD') : null,
                end_date : self.filterDate !== '' && self.filterDate !== null ? Moment(this.filterDate[1]).format('YYYY-MM-DD') : null,
                page: self.currentPage,
                pageSize: 10,
                store_id: this.search.store_id,
                sales_id: this.search.sales_id
            };

            self.$store.dispatch('getTransaction', searchQ)
            .then(res => {
                const { data } = res;
                if(res.data && data.data.length > 0)
                {
                    data.data.forEach(el => {
                        el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                        // el.totalItems = el.items && el.items.length > 0 ? el.items.length : 0
                        // el.isSelect = false;
                        self.dataList.data.push(el)
                    })
                }
            self.total_items = res.meta.total_records
            self.dataList._loading = false;
            })
            .catch(() => {
            self.total_items = 0
            self.dataList._loading = false;
            });
        },
        handleAllSelect(e) {
            console.log(e)
            this.dataList.data.forEach(el => {
                el.isSelect = e;
            })
        },
        updateStatusOrder(type) {
            let dataNeed = {
                ids: [],
                status: ''
            }

            if(type === 'convert' && this.search.status === 'confirmed') {
                dataNeed.status = 'on_delivery';
                dataNeed.courier_id = this.courierSelected;
            }
            if(type === 'convert' && this.search.status === 'on_delivery') dataNeed.status = 'delivered';
            if(type === 'revert' && this.search.status === 'on_delivery') dataNeed.status = 'confirmed';
            if(type === 'revert' && this.search.status === 'delivered') dataNeed.status = 'on_delivery';

            this.dataList.data.forEach(el => {
                if(el.isSelect === true) {
                    dataNeed.ids.push(el.id)
                }
            })

            swal.fire({
                title: 'Are You Sure?',
                text: dataNeed.ids.length +' Transaction data will update status!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
                }).then(async result => {
                if (result.value) {
                    await this.$store.dispatch('updateStatusTrx',dataNeed).then(res => {
                            swal.fire(
                            'Success',
                            'Update Status Transaction Success!',
                            'success'
                        )
                        if(this.search.status === 'confirmed') {
                            this.downloadExcel(dataNeed);
                        }

                        this.courierSelected = null;
                        this.courierSelectedName = null;

                        this.$bvModal.hide('modal-assign-courier');
                        
                        this.loadData();
                        this.selectAll = false;
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            'Update Status Transaction Failed!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        downloadExcel(data) {  
            let fileName = 'Trx On Delivery Japfa.xlsx';
            // this.$store.dispatch('exportExcel',data).then(response => {
            //     const url = URL.createObjectURL(new Blob([response.data], {
            //         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            //     }));
            //     var link = document.createElement('a');
            //     link.href = url;
            //     link.setAttribute('download', fileName)
            //     document.body.appendChild(link)
            //     link.click()
            // })

            axiosAuth.post('admin/orders/export-excel', data, {
                responseType: 'blob',
                headers: {
                    Authorization: 'Bearer ' + this.$localStorage.get('accessTokenJapfaAdmin')
                }
            }).then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }));
                var link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            })
        },
        getMasterData() {
            this.storeOptions = [];
            let searchQ = {
                pageSize: 1000,
                page: 1
            };

            this.$store.dispatch('getStores', searchQ)
            .then(res => {
                const { data } = res;
                if(res.data && data.data.length > 0)
                {
                    data.data.forEach(el => {
                        el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                        this.storeOptions.push(el)
                    })
                }
            })

            this.salesOptions = [];
            let searchQ2 = {
                pageSize: 1000,
                page: 1
            };

            this.$store.dispatch('getUsers', searchQ2)
            .then(res => {
                const { data } = res;
                if(res.data && data.data.length > 0)
                {
                    data.data.forEach(el => {
                        el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                        this.salesOptions.push(el)
                    })
                }
            })
        },

        exportToPDF(dataObj) {
            var vm = this
            let data = dataObj;
            if(data)
            {
                // swal.fire({
                //     title: "Please wait, in the process of Downloading PDF Transaction Invoice..",
                //     allowEscapeKey: false,
                //     allowOutsideClick: false,
                //     onOpen: () => {
                //         swal.showLoading();
                //     }
                // });

                    data.created_at_formatted = Moment(data.destination.created_at).format('DD MMM YYYY HH:mm')
                    data.invoiceDate = Moment().format('DD MMM YYYY');
                    data.invoiceNo = 'SIA-'+data.code.split("-")[1]+'-'+data.code.split("-")[2]

                    const doc = new jsPDF();
                    doc.addImage(this.imagePDF,'PNG',  15, 10, 60, 25);

                    doc.setFontSize(11);
                    doc.text("Japfa Comfeed Myanmar Pte Ltd  ", 200, 19, null, null, "right");
                    doc.text("No.264, 6th street.Yangon Industrial zone", 200, 24, null, null, "right");
                    doc.text("Mingalardon township, Yangon Myanmar 11021", 200, 29, null, null, "right");

                    doc.setFontSize(12);
                    doc.setTextColor(139, 139, 139);
                    doc.text("Order No", 15, 50);
                    doc.text("Date", 15, 60);
                    doc.text("Invoice Date", 15, 70);
                    doc.text("Total Amount", 15, 80);

                    doc.text("Invoice No", 110, 50);
                    doc.text("Name", 110, 60);
                    doc.text("Phone Number", 110, 70);
                    doc.text("Address", 110, 80);

                    doc.setFontSize(12);
                    doc.setTextColor(0, 0, 0);
                    doc.text(data.code, 100, 50, null, null, "right");
                    doc.text(data.created_at_formatted, 100, 60, null, null, "right");
                    doc.text(data.invoiceDate, 100, 70, null, null, "right");
                    doc.text(this.numberFormat(data.total_amount), 100, 80, null, null, "right");

                    doc.text(data.invoiceNo, 200, 50, null, null, "right");
                    doc.text(data.destination.consignee_name, 200, 60, null, null, "right");
                    doc.text(data.destination.consignee_country_code+data.destination.consignee_phone, 200, 70, null, null, "right");
                    var splitTitle2 = doc.splitTextToSize(data.destination.consignee_address+' - '+data.destination.consignee_township, 70);
                    doc.text(110, 90, splitTitle2);

                    doc.setDrawColor(200, 200, 200); // draw lines
                    doc.setLineWidth(0.1);
                    doc.line(105, 45, 105, 107);
                    doc.line(10, 107, 200, 107);

                    // Table
                    let bodyTable = [];
                    let headerTable = [
                        "No",
                        "Product Name",
                        "Qty",
                        "Unit",
                        "Unit Price",
                        "Disc",
                        "Amount"
                      ];

                    data.variants.forEach((el, idx) => {

                        bodyTable.push([
                            idx+1,
                            el.variant_name ? el.variant_name : '-',
                            this.numberFormat(el.qty),
                            el.unit,
                            this.numberFormat(el.amount_per_unit),
                            el.tier_discount_amount,
                            this.numberFormat(el.amount),
                        ])
                    })
                    
                    doc.autoTable(headerTable, bodyTable, {
                        startY: 120,
                    })
                                
                        doc.save('Japfa Invoice ' +data.code+".pdf");

                        swal.fire(
                            'Success!',
                            'Download Invoice '+data.code+' Success.',
                            'success'
                        )

            }
        },
        
      querySearch(queryString, cb) {
        var links = this.courierList;
        var results = queryString ? links.filter(this.createFilter(queryString)) : links;
        // call callback function to return suggestions
        cb(results);
      },
      createFilter(queryString) {
        return (link) => {
          return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      loadAll() {
        let self = this;

        self.courierList = []
        let searchQ = {
            limit: 500,
            page: 1,
        };

        self.$store.dispatch('getCouriers', searchQ)
        .then(res => {
            console.log(res)
            if(res.data && res.data.length > 0)
            {
                res.data.forEach(el => {
                    el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                    el.value = el.name;
                    self.courierList.push(el)
                })
            }
        })
        .catch(() => {
        });
      },
      openCourierForm() {
        this.courierSelected = null;
        this.courierSelectedName = null;

        this.$bvModal.show('modal-assign-courier')
      },
      handleSelect(item) {
        this.courierSelected= item.id;
      }
    },
    mounted()
    {
      document.title = 'Transaction - Kujangmas Admin'
      this.getMasterData();
      this.loadData();

        this.$watch('filterDate', _.debounce((newVal) => {
            this.loadData();
          }, 100));
        //   this.loadAll();

    }
}