import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/style/scss/style.scss'
import '@/assets/style/font/style.css'
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueLocalStorage from 'vue-localstorage'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import VueObserveVisibility from 'vue-observe-visibility'
import BootstrapVue from 'bootstrap-vue'
import {library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
window.swal = require("sweetalert2");
window.axios = require('axios');
window.$ = require('jquery');
window.Moment = require('moment');
var _ = require('lodash');

Vue.config.productionTip = false
Vue.use(VueObserveVisibility)
Vue.use(VueLocalStorage)
Vue.use(BootstrapVue);
Vue.use(ElementUI, {locale});

Vue.component('fa', FontAwesomeIcon)

library.add(fas)
library.add(far)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
