export default {
    name: "FormStore",
    data() {
        return {
            process: false,
            storeId: null,
            imgTemp: null,
            dialogImageUrl: '',
            imgTempKtp: null,
            dialogImageKtpUrl: '',
            storeTypeOptions: [],
            province: [],
            cities: [],
            district: [],
            subdistrict: [],
            form: {
                address: "",
                address_city: "",
                address_district: "",
                address_province: "",
                address_subdistrict: "",
                code: "",
                credit_limit: null,
                description: "",
                image_url: "",
                latitude: null,
                longitude: null,
                name: "",
                pic_ktp_photo: "",
                pic_name: "",
                pic_phone_number: "",
                region_code: "",
                type_id: null,
                zipcode: ""
            },
        }
    },

    props: {
        formData: {
            default: null,
            type: Object,
            required: true
        },
        formMode: {
            default: '',
            type: String,
            required: true
        },
    },

    computed: {
        disabledSave() {
            let invalid = false;

            if(this.form.name === '' || this.form.name === null) {
                invalid = true;
            }
            if(this.form.code === '' || this.form.code === null) {
                invalid = true;
            }
            if(this.form.type_id === '' || this.form.type_id === null) {
                invalid = true;
            }
            if(this.form.address_province === '' || this.form.address_province === null) {
                invalid = true;
            }
            if(this.form.address_city === '' || this.form.address_city === null) {
                invalid = true;
            }
            if(this.form.address_district === '' || this.form.address_district === null) {
                invalid = true;
            }
            if(this.form.address_subdistrict === '' || this.form.address_subdistrict === null) {
                invalid = true;
            }
            if(this.form.latitude === '' || this.form.latitude === null) {
                invalid = true;
            }
            if(this.form.longitude === '' || this.form.longitude === null) {
                invalid = true;
            }
            if(this.form.pic_name === '' || this.form.pic_name === null) {
                invalid = true;
            }
            if(this.form.pic_phone_number === '' || this.form.pic_phone_number === null) {
                invalid = true;
            }

            return invalid
        }
    },

    methods: {
        selectFile(i = null)
        {
                $('#inputIMG-'+i).click();
        },
        previewImageKtp: function(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.dialogImageKtpUrl = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        previewImage: function(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.dialogImageUrl = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        initForm() {
            if(this.formMode === 'edit') {

                this.storeId = this.formData.id;
                this.form.address= this.formData.address;
                this.form.address_city= this.formData.address_city;
                this.form.address_district= this.formData.address_district;

                this.form.address_province= this.formData.address_province;
                
                this.form.address_subdistrict= this.formData.address_subdistrict;
                this.form.code= this.formData.code;
                this.form.credit_limit= this.formData.credit_limit;
                this.form.description= this.formData.description;
                this.form.image_url= this.formData.image_url;
                this.form.latitude= this.formData.latitude;
                this.form.longitude= this.formData.longitude;
                this.form.name= this.formData.name;
                this.form.pic_ktp_photo= this.formData.pic_ktp_photo;
                this.form.pic_name= this.formData.pic_name;
                this.form.pic_phone_number= this.formData.pic_phone_number;
                this.form.region_code= this.formData.region_code;
                this.form.type_id= this.formData.type_id;
                this.form.zipcode= this.formData.zipcode;

                this.dialogImageUrl = this.formData.image_url? this.formData.image_url: '';
                this.dialogImageKtpUrl = this.formData.pic_ktp_photo? this.formData.pic_ktp_photo: '';
            }else{
                    this.form = {
                        address: "",
                        address_city: "",
                        address_district: "",
                        address_province: "",
                        address_subdistrict: "",
                        code: "",
                        credit_limit: null,
                        description: "",
                        image_url: "",
                        latitude: null,
                        longitude: null,
                        name: "",
                        pic_ktp_photo: "",
                        pic_name: "",
                        pic_phone_number: "",
                        region_code: "",
                        type_id: null,
                        zipcode: ""
                    }
            }
        },

        handleSelectRegion(val, type) {
            switch (type) {
                case 'province':
                    this.form.address_city = '';
                    this.form.address_district = '';
                    this.form.address_subdistrict = '';
                    this.cities = [];
                    this.district = [];
                    this.subdistrict = [];
                    this.getRegion('city');
                    break;
                case 'city':
                    this.form.address_district = '';
                    this.form.address_subdistrict = '';
                    this.district = [];
                    this.subdistrict = [];
                    this.getRegion('district');
                    break;
                case 'district':
                    this.form.address_subdistrict = '';
                    this.subdistrict = [];
                    this.getRegion('subdistrict');
                    break;
                case 'subdistrict':
                    this.form.region_code = this.subdistrict.find(x => x.subdistrict === val).code;
                    break;
            
                default:
                    break;
            }
        },

        async saveData() {
            let self = this;

            self.process = true;

            if(self.imgTemp) {
                let fd = new FormData();
                fd.append('file', self.imgTemp);
                fd.append('filename', self.imgTemp.name);

                await self.$store.dispatch('uploadFile', fd)
                .then(async res => {
                    this.form.image_url = await res.data.url
                }).catch(err => {
                   return this.$bvToast.toast(
                        err.message,
                        {
                        title: "Upload image Gagal",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: false,
                        toaster: "b-toaster-top-right"
                        }
                    );
                })
            }

            if(self.imgTempKtp) {
                let fd = new FormData();
                fd.append('file', self.imgTempKtp);
                fd.append('filename', self.imgTempKtp.name);

                await self.$store.dispatch('uploadFile', fd)
                .then(async res => {
                    this.form.pic_ktp_photo = await res.data.url
                }).catch(err => {
                   return this.$bvToast.toast(
                        err.message,
                        {
                        title: "Upload image Gagal",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: false,
                        toaster: "b-toaster-top-right"
                        }
                    );
                })
            }

            setTimeout(() => {
                    if(this.formMode === 'add') {
                        const payload = this.form;
    
                        delete payload.credit_limit;
                        payload.latitude = payload.latitude ? Number(payload.latitude) : null;
                        payload.longitude = payload.longitude ? Number(payload.longitude) : null;
                        payload.type_id = payload.type_id ? Number(payload.type_id) : null;
                        self.$store.dispatch('addStore', payload)
                        .then(res => {
                            this.$emit("closeModal", true);
                            swal.fire(
                                'Success!',
                                'Tambah Toko Berhasil!',
                                'success'
                                )
                            self.process = false;
                        })
                        .catch(err => {
                            swal.fire(
                                'Failed!',
                                'Tambah Toko Gagal. '+ (err?.data?.error ?? 'Terjadi Kesalahan.'),
                                'error'
                            )
                            self.process = false;
                        })
                    } else {
                        let dataNeed = {
                            id : this.storeId,
                            form : this.form
                        }
    
                        dataNeed.form.created_at = this.formData.created_at;
                        dataNeed.form.credit_limit = Number(dataNeed.form.credit_limit);
                        dataNeed.form.latitude = dataNeed.form.latitude ? Number(dataNeed.form.latitude) : null;
                        dataNeed.form.longitude = dataNeed.form.longitude ? Number(dataNeed.form.longitude) : null;
                        self.$store.dispatch('UpdateStore', dataNeed)
                        .then(res => {
                            this.$emit("closeModal", true);
                            swal.fire(
                                'Success!',
                                'Update Toko Berhasil!',
                                'success'
                                )
                            self.process = false;
                        })
                        .catch(err => {
                            this.$emit("closeModal", true);
                            swal.fire(
                                'Failed!',
                                'Update Toko Gagal. '+ err.message,
                                'error'
                            )
                            self.process = false;
                        })
                    }
            }, 500);
        },

        getMasterData() {
            this.storeTypeOptions = [];

            let searchQ = {
                page: 1,
                pageSize: 1000
            };

            this.$store.dispatch('getStoreType', searchQ)
            .then(res => {
                const { data: { data } } = res;
                this.storeTypeOptions = data || [];
            })
        },

        getRegion(type) {
            const payload = {
                province: this.form.address_province,
                city: this.form.address_city,
                district: this.form.address_district,
                subdistrict: this.form.address_subdistrict,
                code: "",
                type: type
              }

            this.$store.dispatch('getRegion', payload)
            .then(res => {
                const { data } = res;
                switch (type) {
                    case 'province':
                        this.province = data || [];
                        this.form.address_province = '';
                        this.form.address_city = '';
                        this.form.address_district = '';
                        this.form.address_subdistrict = '';
                        this.cities = [];
                        this.district = [];
                        this.subdistrict = [];
                        break;
                    case 'city':
                        this.cities = data || [];
                        this.form.address_city = '';
                        this.form.address_district = '';
                        this.form.address_subdistrict = '';
                        this.district = [];
                        this.subdistrict = [];
                        break;
                    case 'district':
                        this.district = data || [];
                        this.form.address_district = '';
                        this.form.address_subdistrict = '';
                        this.subdistrict = [];
                        break;
                    case 'subdistrict':
                        this.subdistrict = data || [];
                        this.form.address_subdistrict = '';
                        break;
                
                    default:
                        break;
                }
            })
        }

    },
    mounted() {
        this.getMasterData();
        this.getRegion('province');
        setTimeout(() => {
            this.initForm();
        }, 250);
    }
}