<template>
     <div class="d-flex justify-content-between header-content py-3">
         <div class="d-flex">
             <img @click="showHideSide()" width="20" src="@/assets/img/icon/burger-ic.svg" alt="" class="cursor-pointer">
             <h5 class="ml-3 mb-0 align-self-center">{{ $route.meta.displayName }}</h5>
         </div>
            <div>
                <b-dropdown variant="link" dropleft toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <i class="el-icon-setting" style="margin-right: 15px"></i>
                </template>
                <b-dropdown-item href  @click="$bvModal.show('bv-modal-example')">Logout</b-dropdown-item>
            </b-dropdown>
            </div>

            <b-modal centered size="sm" id="bv-modal-example" hide-footer>
                <template slot="modal-title">
                    Confirm the Sign Out
                </template>
                <div class="d-block text-center">
                    <h5>Are you sure exit from this system ?</h5>
                </div>
                <b-button :variant="proccess ? false : 'primary'" :disabled="proccess" class="mt-4" block @click="signOut">
                        <span v-if="proccess">
                            <b-spinner small variant="success" label="Spinning"></b-spinner>
                            Sign Out
                        </span>
                    <span v-else>
                            Yes, i will Sign Out
                        </span>
                </b-button>
                </b-modal>
     </div>
</template>

<script>
import Vue from 'vue'
import { LayoutPlugin } from 'bootstrap-vue'

Vue.use(LayoutPlugin)

export default {
  name: 'Navigate',
  data () {
    return {
      proccess: false,
    };
  },
  methods: {
    signOut () {
      this.proccess = true;
      this.$store.dispatch("logout").then(r => {
            setTimeout(() => {
                this.$router.push({path: '/login'})
                this.proccess = false
            },1000)
        });
    },
    showHideSide() {
      this.$emit('clicked', 'show_hide_side')
    }
  },
  computed: {},
  watch: {},
  mounted () {},
}
</script>

<style lang="scss">
.header-content {
    
}
</style>