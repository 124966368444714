export default {
    name: "FormPromo",
    data() {
        return {
            promoId: null,
            form: {
                code: "",
                name: "",
                image_url: "",
                description: "",
                discount_type: null,
                is_active: true,
                discount_value: 0,
                has_minimum_purchase: false,
                minimum_purchase_value: null,
                minimum_purchase_type: null,
                valid_until: '',
                total_used: 0,
            },
            imgTemp: null,
            dialogImageUrl: '',
            discountTypeOptions: [
                {
                    label : 'Nominal',
                    value: 'nominal'
                },
                {
                    label : 'Persentase',
                    value: 'percentage'
                },
                // {
                //     label : 'Bonus Barang',
                //     value: 'item_bonus'
                // }
            ],
            minimumPurchaseTypeOptions: [
                {
                    label : 'Jumlah Qty',
                    value: 'quantity'
                },
                {
                    label : 'Jumlah Nominal',
                    value: 'amount'
                },
                // {
                //     label : 'by Area',
                //     value: 'by_area'
                // },
                // {
                //     label : 'Spesific User',
                //     value: 'specific'
                // }
            ],
        }
    },

    props: {
        formData: {
            default: null,
            type: Object,
            required: true
        },
        formMode: {
            default: '',
            type: String,
            required: true
        },
    },

    computed: {
        disabledSave() {
            let valid = false;

            if(this.form.code === '' || this.form.code === null) {
                valid = true;
            }
            if(this.form.name === '' || this.form.name === null) {
                valid = true;
            }
            if(this.form.description === '' || this.form.description === null) {
                valid = true;
            }
            if(this.form.discount_type === '' || this.form.discount_type === null) {
                valid = true;
            }
            if(this.form.discount_value === '' || this.form.discount_value === null || this.form.discount_value <= 0) {
                valid = true;
            }
            if(this.form.valid_until === '' || this.form.valid_until === null) {
                valid = true;
            }

            return valid
        }
    },

    methods: {
        generateCode(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
               result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return this.form.code = result;
         },
        initForm() {
            if(this.formMode === 'edit') {
                this.promoId = this.formData.id;
                this.dialogImageUrl = this.formData.image_url? this.formData.image_url: '';
                    this.form.code= this.formData.code;
                    this.form.name= this.formData.name;
                    this.form.image_url= this.formData.image_url;
                    this.form.description= this.formData.description;
                    this.form.discount_type= this.formData.discount_type;
                    this.form.discount_value= this.formData.discount_value;
                    this.form.total_used= this.formData.total_used;
                    this.form.has_minimum_purchase= this.formData.has_minimum_purchase?this.formData.has_minimum_purchase:false;
                    this.form.minimum_purchase_type= this.formData.minimum_purchase_type?this.formData.minimum_purchase_type:null;
                    this.form.minimum_purchase_value= this.formData.minimum_purchase_value?this.formData.minimum_purchase_value:null;
                    this.form.valid_until= new Date(this.formData.valid_until);
            }else{
                    this.form = {
                        code: "",
                        name: "",
                        image_url: "",
                        description: "",
                        discount_type: null,
                        is_active: true,
                        discount_value: 0,
                        has_minimum_purchase: false,
                        minimum_purchase_value: null,
                        minimum_purchase_type: null,
                        valid_until: '',
                        total_used: 0,
                    }
            }
        },
        selectFile()
        {
                $('#inputIMG').click();
        },
        previewImage: function(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.dialogImageUrl = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },

        validate(evt) {
            var theEvent = evt || window.event;
          
            // Handle paste
            if (theEvent.type === 'paste') {
                key = event.clipboardData.getData('text/plain');
            } else {
            // Handle key press
                var key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
            }
            var regex = /[0-9]|\./;
            if( !regex.test(key) ) {
              theEvent.returnValue = false;
              if(theEvent.preventDefault) theEvent.preventDefault();
            }
          },

        async saveData() {
            let self = this;

            if(self.imgTemp) {
                let fd = new FormData();
                fd.append('file', self.imgTemp);
                fd.append('filename', self.imgTemp.name);

                await self.$store.dispatch('uploadFile', fd)
                .then(async res => {
                    this.form.image_url = await res.data.url
                }).catch(err => {
                   return this.$bvToast.toast(
                        err.message,
                        {
                        title: "Upload image Failed",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: false,
                        toaster: "b-toaster-top-right"
                        }
                    );
                })
            }

            const payload = this.form;

            payload.discount_value = Number(this.form.discount_value)

            if(payload.minimum_purchase) {
                payload.minimum_purchase = Number(this.form.minimum_purchase)
            }

            if (!payload.has_minimum_purchase) {
                delete payload.minimum_purchase_type;
                delete payload.minimum_purchase_value;
            }

            if(payload.total_used) {
                payload.total_used = Number(this.form.total_used)
            }

            payload.valid_until = Moment(this.form.start_date).format('YYYY-MM-DD')+ 'T00:00:00Z';

            setTimeout(() => {
                if(this.formMode === 'add') {
                    self.$store.dispatch('addPromo', payload)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Menambah Promo Berhasil!',
                            'success'
                            )
                    })
                    .catch(err => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Failed!',
                            'Menambah Promo Gagal. '+ err.message,
                            'error'
                        )
                    })
                }else{
                    let dataNeed = {
                        id : this.promoId,
                        form : this.form
                    }
                    self.$store.dispatch('UpdatePromo', dataNeed)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Update Promo Success!',
                            'success'
                            )
                    })
                    .catch(err => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Failed!',
                            'Update Promo Failed. '+ err.message,
                            'error'
                        )
                    })
                }
            }, 500);
            
        },

    },
    mounted() {
        this.initForm();
    }
}