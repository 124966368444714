export default {
    name: "FormPassword",
    data() {
        return {
            process: false,
            userId: null,
            form: {
                password: "",
                repassword: "",
            },
        }
    },

    props: {
        formData: {
            default: null,
            type: Object,
            required: true
        },
        formMode: {
            default: '',
            type: String,
            required: true
        },
    },

    computed: {
        disabledSave() {
            let invalid = false;

            if(this.form.password === '' || this.form.password === null) {
                invalid = true;
            }
            
            if(this.form.repassword === '' || this.form.repassword === null) {
                invalid = true;
            }

            return invalid
        }
    },

    methods: {
        initForm() {
            this.form = {
                password: "",
                repassword: "",
            }
        },

        async saveData() {
            let self = this;
            if (this.form.password !== this.form.repassword) {
                swal.fire(
                    'Failed!',
                    'Password dan Konfirmasi Password tidak sama!',
                    'error'
                )
                return;
            }

            self.process = true;

            setTimeout(() => {
                    let dataNeed = {
                        id : this.formData.id,
                        form : {
                            id : this.formData.id,
                            password : this.form.password,
                        }
                    }

                    self.$store.dispatch('UpdatePassUser', dataNeed)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Update Password Berhasil!',
                            'success'
                            )
                            self.process = false;
                    })
                    .catch(err => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Failed!',
                            'Update Password Gagal. '+ err.response?.data?.error,
                            'error'
                        )
                        self.process = false;
                    })
            }, 500);
            
        },

    },
    mounted() {
        this.initForm();
    }
}