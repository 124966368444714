<template>
    <div class="row">
        <div class="col-12 mt-5">
            <div class="text-center mb-5" style="
                background: #dc3545;
                padding: 22px;
                color: white;
                border-radius: 8px;">
                <h3>About</h3>
            </div>
           <div class="row">
               <div class="col-md-6 mb-4">
                   <h3>Application</h3>
                   <hr>
                   <div class="row mt-4">
                       <div class="col-3">
                            <img class="w-100" src="@/assets/img/japfa-ic.png" alt="">
                       </div>
                       <div class="align-self-center col">
                           <h4>Chicken and Eggs Apps</h4>
                           <p>Market place for egg product, chicken and others</p>
                           <p>Chicken and Eggs: <br>
                                Shop smart with home delivery solutions <br>
                                - Fresh from the farm<br>
                                -Easy online orders and shopping<br>
                                - Weekly promos, deals and vouchers <br>
                                - Exclusive promo on your first order<br>
                                - Chat for update availability</p>
                       </div>
                   </div>
               </div>
               <div class="col-md-6 mb-4">
                   <h3>Company</h3>
                   <hr>
                   <div class="row mt-4">
                       <div class="col-3 align-self-center ">
                            <img class="w-100" src="@/assets/img/japfa-logo.jpeg" alt="">
                       </div>
                       <div class="align-self-center col">
                           <h4>Japfa Comfeed Myanmar Pte. Ltd</h4>
                           <p>Address: No.264, 6 street, Yangon Industrial Zone, Yangon, Myanmar (Burma)</p>
                           <p>Phone: +95 9 732 32741</p>
                       </div>
                   </div>
               </div>
               <div class="col-md-6 mb-4">
                   <h3>Contact Information</h3>
                   <hr>
                   <div class="row mt-4">
                       <div class="align-self-center col-auto">
                           <p>-> name : mr. budi cahyo <br>
                            -> phone number : +959970480127 <br>
                            -> email : Budi.cahyo@japfa.com</p>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'About'
}
</script>