<template>
  <div id="app">
    <el-container style="height: 100vh; border: 1px solid #eee" v-if="$route.path !== '/terms-condition' && $route.path !== '/about'">
        <el-aside width="240px" id="sideBar" v-if="$route.name !== 'Login'" style="background-color: #F6F6F6;">
            <Sidebar/>
        </el-aside>
    
        <el-container class="content" id="mainContent" :class="{'ml-content': $route.name !== 'Login'}">
          <el-header class="sticky-top shadow-sm border-bottom" v-if="$route.name !== 'Login'" @click="closeNav">
              <HeaderNav @clicked="onClickChild($event)" />
          </el-header>
          
          <el-main id="main" :class="{'py-3 px-4': $route.name !== 'Login', 'p-0':$route.name === 'Login'}" style="position: relative;">
            <router-view/>
          </el-main>
        </el-container>
     </el-container>
     <b-container v-if="$route.path === '/terms-condition' || $route.path === '/about'">
            <router-view/>
     </b-container>
  </div>
</template>

<style lang="scss">
   .el-header {
     background: white;
    color: #333;
    text-align: right; 
    font-size: 16px;
    height: auto !important;
  }
  .ml-content {
    margin-left: 240px;
    transition: 0.5s;
  }
  .el-aside {
    transition: 0.5s;
    color: #333;
    overflow-y: auto !important;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    //     /* width */
    &::-webkit-scrollbar {
    width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
    background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
    background: rgb(163, 162, 162);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
    background: rgb(167, 167, 167);
    }
  }
</style>

<script>
import Sidebar from './components/Sidebar.vue'
import HeaderNav from './components/HeaderNav.vue'

  export default {
    components: {Sidebar, HeaderNav},
    data() {
      const item = {
        date: '2016-05-02',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles'
      };
      return {
        tableData: Array(20).fill(item),
        isShowSide: true
      }
    },
    methods: {
      openNav() {
        document.getElementById("sideBar").style.width = "240px";
        document.getElementById("mainContent").style.marginLeft = "240px";
      },
      closeNav() {
        document.getElementById("sideBar").style.width = "0";
        document.getElementById("mainContent").style.marginLeft= "0";
      },
      onClickChild(e) {
        if(this.isShowSide) {
          this.closeNav()
        }else{
          this.openNav()
        }
        this.isShowSide = !this.isShowSide;
      }
    }
  };
</script>
