<template>
    <div class="px-3 detail-trx-content" v-if="formData">
        <div class="d-flex justify-content-between mb-1">
            <h6>No Invoice</h6>
            <p>{{formData.order.invoice_number}}</p>
        </div>
        <div class="d-flex justify-content-between mb-1">
            <h6>Tanggal Transaksi</h6>
            <p>{{formData.date_formatted}}</p>
        </div>
        <div class="d-flex justify-content-between mb-1">
            <h6>Nama Toko</h6>
            <p>{{formData.order.store_name}}</p>
        </div>
        <div class="d-flex justify-content-between mb-1">
            <h6>Nama Penjual</h6>
            <p>{{formData.order.sales_name}}</p>
        </div>

        <!-- <div v-if="formData.status !== 'confirmed'" class="courier-section d-flex justify-content-between align-items-center mb-1">
            <h6 class="mb-0">Courier Name</h6>
            <p class="mb-0">{{formData.courier_name || '-'}}</p>
        </div> -->


        <hr>
        <div v-if="formData.destination">
            <div class="d-flex justify-content-between mb-1">
                <h6>Penerima</h6>
                <p>{{formData.destination.consignee_name}}</p>
            </div>
            <div class="d-flex justify-content-between mb-1">
                <h6>Phone Number</h6>
                <p>{{formData.destination.consignee_contact}}</p>
            </div>
            <div class="mb-1">
                <h6>Alamat</h6>
                <p class="mt-3 py-2 px-3 round-sm bg-gray">{{formData.destination.address}}</p>
            </div>
        </div>


        <hr>
        <div v-if="formData.payment">
            <div class="d-flex justify-content-between mb-1">
                <h6>Cara Bayar</h6>
                <p>{{formData.payment.payment_method}}</p>
            </div>
            <div class="d-flex justify-content-between mb-4">
                <h6>Status</h6>
                <div>
                    <span v-if="formData.payment.status === 'paid'" class="text-success">Sudah dibayar</span>
                    <span v-if="formData.payment.status === 'unpaid'" class="text-danger">Belum dibayar</span>
                </div>
            </div>
            <div class="mb-1">
                <h6>Bukti Pembayaran</h6>
                <img v-if="formData.payment.proof_paid_transfer_url" width="320" :src="formData.payment.proof_paid_transfer_url" alt="" style="object-fit:cover;">
                <h5 v-else>-</h5>
            </div>
        </div>

        <!-- ITEMS SECTION -->
        <hr>
        <div class="d-flex justify-content-between mb-2">
            <h6>Produk</h6>
            <p>Total {{formData.products.length > 0 ? formData.products.length : ''}}</p>
        </div>
        <div v-if="formData.products && formData.products.length > 0" v-for="(each, idx) in formData.products" :key="idx">
            <div class="d-flex justify-content-between mb-1" >
                <div class="d-flex">
                    <img width="60" height="60" :src="each.product_image?each.product_image: (each.product_image_url?each.product_image_url : noPhoto)" alt="" style="object-fit:cover;">
                    <div class="ml-3">
                        <h6>Nama: {{each.product_name || '-'}}</h6>
                        <p class="text-muted">qty: {{each.quantity}}</p>
                    </div>
                </div>
                    <p class="align-self-center"> Rp{{numberFormat(each.unit_price)}}</p>
            </div>
            <!-- <div class="mb-1">
                <h6>Notes</h6>
                <p class="mt-3 py-2 px-3 round-sm bg-gray">{{each.notes || '-'}}</p>
            </div> -->
        </div>
        <div v-if="formData.products.length === 0">
            <b-alert show variant="warning">No items</b-alert>
        </div>
        <hr>
        <!-- ITEMS SECTION END -->
        <!-- <div class="d-flex justify-content-between mb-1">
            <h6>Total Items Price</h6>
            <p>{{numberFormat(formData.total_product_amount)}} Ks</p>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <h6>Shipping Cost</h6>
            <p>{{numberFormat(formData.shipping_cost)}} Ks</p>
        </div>
        <div class="d-flex justify-content-between mb-2 text-danger" v-if="formData.promo_amount > 0">
            <h6>Promo : {{formData.promo_code}}</h6>
            <p> - {{numberFormat(formData.promo_amount)}} Ks</p>
        </div>
        <div class="d-flex justify-content-between mb-1">
            <h6>Total Prices</h6>
            <h5> {{numberFormat(formData.total_amount)}} Ks</h5>
        </div> -->

        <!-- <div class="mt-3 mb-2" v-if="formData.status === 'waiting_pickup'">
        <b-button variant="success w-100 py-2" @click="dialogDeliveryCode = true">Confirm Pickup</b-button>
        <el-dialog
                title="Input Delivery Code"
                :visible.sync="dialogDeliveryCode"
                width="30%">
                <div >
                      <b-form-group  >
                        <b-form-input
                            type="text"
                            placeholder="Input Delivery Code"
                            required
                            v-model="deliveryCode"
                            ></b-form-input>
                    </b-form-group>
                    <b-button variant="success w-100" @click="convertOrder('on_delivery')">Submit</b-button>
                  </div>
              </el-dialog>
        </div> -->

        <!-- <div class="mt-3 mb-2" v-if="formData.status === 'on_delivery'">
            <b-button variant="success w-100 py-2" @click="convertOrder('delivered')">Finish Delivery</b-button>
        </div> -->
    </div>
</template>

<script>
export default {
    name:'detailTransaction',
    data() {
        return {
            noPhoto: require('@/assets/img/icon/no-photo.jpg'),
            deliveryCode: '',
            dialogDeliveryCode: false,
            variantsData: []
        }
    },
    props: {
        formData: {
            default: null,
            type: Object,
            required: true
        }
    },
    methods: {
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        convertOrder(type) {
            let self = this;

            let isValid = false;

            let dataNeed = null;
            if(type === 'on_delivery') {
                dataNeed = {
                    id : this.formData.id,
                    form: {
                        status: "on_delivery",
                        delivery_code: this.deliveryCode
                    }
                }
            } else if (type === 'delivered') {
                dataNeed = {
                    id : this.formData.id,
                    form: {
                        status: "delivered",
                        delivery_code: this.formData.delivery_code
                    }
                }
                isValid = true;
            }

            if(type === 'on_delivery' && (this.deliveryCode === '' || this.deliveryCode === null)) {
                return swal.fire(
                        'Warning!',
                        'Please fill Delivery Code First!',
                        'warning'
                    )
            }else{
                isValid = true;
            }

           if(isValid) {
               swal.fire({
                title: 'Are You Sure?',
                text: 'this Transaction Data will be change status!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel'
                }).then(async result => {
                if (result.value) {
                    await self.$store.dispatch('convertStatusTrx', dataNeed)
                        .then(res => {
                            this.$emit("closeModal", true);
                            swal.fire(
                                'Success!',
                                'Change Status Transaction Success!',
                                'success'
                                )
                        })
                        .catch(err => {
                            this.$emit("closeModal", true);
                            swal.fire(
                                'Failed!',
                                'Change Status Transaction Failed. '+ err.message,
                                'error'
                            )
                        })
                }
                }).catch(function(error) {
                    console.log(error)
                });
           }
        }
    },
    mounted() {
        this.deliveryCode = '';
        if(this.formData && this.formData.variants && this.formData.variants.length > 0){
            this.variantsData = this.formData.variants
        }
    }
}
</script>

<style lang="scss">
    .v-modal {
        z-index: 0 !important;
        opacity: .2 !important;
    }
    .courier-section {
        background: #f08f2ab3;
        padding: 12px;
        border-radius: 10px;
    }
</style>