<template>
<div>
    <div class="p-4 ml-3 d-flex">
        <img width="45" src="@/assets/img/icon/kujang-ic.png" alt="">
        <h5 class="mb-0 align-self-center ml-3">Kujangmas</h5>
    </div>
    <div class="mt-4 menu-list">
        <el-menu :default-active="$route.name" @select="menuSelect">
            <el-menu-item index="Dashboard"><img width="16" src="@/assets/img/icon/menu/home-ic.png" alt="" class="mr-3"> Dashboard</el-menu-item>
            <el-menu-item index="Product"><img width="16" src="@/assets/img/icon/menu/truck-ic.png" alt="" class="mr-3"> Produk</el-menu-item>
            <el-submenu index="1" class="warehouse-submenu">
                <template slot="title">
                <i class="el-icon-location mr-3" style="width: 16px"></i>
                <span>Gudang</span>
                </template>
                <el-menu-item index="Warehouse">Gudang Induk</el-menu-item>
                <el-menu-item index="WarehouseStock">Gudang Stok</el-menu-item>
                <el-menu-item index="WarehouseTransit">Gudang Transit</el-menu-item>
            </el-submenu>
            <!-- <el-menu-item index="Stock"><img width="16" src="@/assets/img/icon/menu/komplain-ic.png" alt="" class="mr-3"> Stock</el-menu-item> -->
            <el-menu-item index="Transaction"><img width="16" src="@/assets/img/icon/menu/trans-ic.png" alt="" class="mr-3"> Transaksi</el-menu-item>
            <el-menu-item index="Users"><img width="16" src="@/assets/img/icon/menu/user-ic.png" alt="" class="mr-3"> Pengguna</el-menu-item>
            <el-menu-item index="Store"><i class="el-icon-s-shop mr-3" style="width: 16px"></i>Toko</el-menu-item>
            <el-menu-item index="Promo"><img width="16" src="@/assets/img/icon/menu/promo-ic.png" alt="" class="mr-3"> Promo</el-menu-item>
        </el-menu>
    </div>
</div>
</template>

<script>
// import basePath from '@/plugins/basePath.js'
export default {
    name: "Sidebar",
    data() {
        return {
            company: {
                data: [],
                selected: null
            }
        }
    },
    methods: {
        menuSelect(index) {
            if (this.$route.name !== index) {
            this.$router.push({
                name: index,
            });
            }
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
.profile {
    .name-profile {
        font-weight: 600;
    }
    img {
        border-radius: 50%;
        background-size: cover;
    }
}
.circle-blue {
    width: 13px;
    height: 13px;
    background: #0198E7;
    border-radius: 50%;
}
.el-menu {
    background-color: #F6F6F6 !important;
}
.el-menu-item, .el-submenu__title {
    font-size: 14px !important;
    height: 45px !important;
    line-height: 45px !important;
    color: #6F6C99 !important;
    padding-left: 30px !important;
    margin-bottom: 11px;
}
.el-menu-item.is-active {
    font-family: 'SF UI Text Medium';
    border-left: 6px solid #FF9939;
    background-color: #F0F0F0;
    color: black !important;
}
.el-menu-item:focus, .el-menu-item:hover {
    background-color: #F0F0F0 !important;

}

.warehouse-submenu .el-menu {
    margin-left: 2rem;
}
// .menu-list {
//     overflow-y: auto !important;
//     height: 66vh;
//     /* width */
//     &::-webkit-scrollbar {
//     width: 10px;
//     }

//     /* Track */
//     &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//     }

//     /* Handle */
//     &::-webkit-scrollbar-thumb {
//     background: rgb(163, 162, 162);
//     }

//     /* Handle on hover */
//     &::-webkit-scrollbar-thumb:hover {
//     background: rgb(167, 167, 167);
//     }
// }
</style>