import FormUser from './Form-User/Form-User.vue';
import FormPassword from './Form-Password/Form-Password.vue';

export default {
    name: 'User',
    components : { FormUser, FormPassword },
    data() {
        return {
            form:{
                name: 'Veronica',
                company: 'Veridian Dynamics'
            },
            search : {
                keyword : ''
            },
            modeForm: 'add',
            loading: true,
            isMode : 'show',
            total_items: 0,
            currentPage: 1,
            isContent : 'Barang',
            listHead : [
                {
                    title: 'Barang',
                    notif : null
                }
            ],
             options: [
                { value: '', text: 'Pilih Unit', disabled: true },
                { value: 'bal', text: 'bal' },
                { value: 'dus', text: 'dus' },
                { value: 'pak', text: 'pak' },
                { value: 'krg', text: 'krg' },
                { value: 'kg', text: 'kg' },
                { value: 'pcs', text: 'pcs' },
                { value: 'rcg', text: 'rcg' },
                { value: 'peti', text: 'peti' },
            ],
            dataList : {
                data : [],
                detailData: {},
                _loading : true,
                pagination: {
                    "page": 1,
                    "total_pages": 1,
                    "total_records": 1,
                    "prev": 1,
                    "next": 1,
                    "record_per_page": 1
                },
                limit: 5,
                form : [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
            }
        }
    },

    watch: {
        "currentPage"()
        {
            let self = this

            if(self.currentPage != 1)
            {
                    self.loadData()
            }else {
                    self.loadData()
            }
        },
    },
    computed : {
        totalItem(){

            return this.total_items
        },
    },

    methods: {
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        closeModal(e) {
            this.$bvModal.hide('modal-product-form')
            this.$bvModal.hide('modal-password-form')
            this.loadData()
        },
        initForm()
        {
            this.dataList.form = [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
        },
        addForm()
        {
            this.modeForm = 'add';
            this.$bvModal.show('modal-product-form')
            
        },
        removeForm(x)
        {
            if(this.dataList.form.length == 1)
            {
                return null
            }else {
                return  this.dataList.form.splice(x, 1);
            }
            
        },
        editData(data)
        {
            this.modeForm = 'edit';
            // this.$store.dispatch('getUsersDetail',id)
            // .then(res => {
            //     res.date_formatted = Moment(res.date_formatted).format('DD MMM YYYY')
            //     this.dataList.detailData = res
            //     this.$bvModal.show('modal-product-form')
            // })

            this.dataList.detailData = data;
            this.$bvModal.show('modal-product-form')
        },
        openPassDialog(data)
        {
            this.dataList.detailData = data;
            this.$bvModal.show('modal-password-form')
        },
        deleteData(id, nama)
        {
            swal.fire({
                title: 'Apakah Kamu yakin?',
                text: 'User '+ nama +' akan dihapus secara permanen!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batalkan'
                }).then(async result => {
                if (result.value) {
                    await this.$store.dispatch('DeleteUser',id).then(res => {
                            swal.fire(
                            'Success',
                            'Hapus User '+ nama +' Berhasil!',
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            'Hapus User Gagal!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        loadData(page)
        {
            let self = this;
            page = page || 1

            self.dataList._loading = true
            self.dataList.data = []
            let searchQ = {
                keyword: self.search.keyword,
                pageSize: Number(this.dataList.limit),
                page: page,
            };

            self.$store.dispatch('getUsers', searchQ)
            .then(res => {
                const { data } = res;
                if(res.data && data.data.length > 0)
                {
                    data.data.forEach(el => {
                        el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                        self.dataList.data.push(el)
                    })
                }
            self.dataList.pagination = data.metadata
            self.total_items = data.metadata.totalData
            self.dataList._loading = false;
            })
            .catch(() => {
            self.total_items = 0
            self.dataList._loading = false;
            });
        },

        getOverallIndex(index) {
            return (this.dataList.pagination.page* this.dataList.limit)-this.dataList.limit + index + 1
          },
    },
    mounted()
    {
      document.title = 'Pengguna - Kujangmas Admin'
        this.loadData();
    }
}